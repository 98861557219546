import EcoMain from "../components/courses/eco/EcoMain";
import EcoMain2 from "../components/courses/eco/EcoMain2";
import EcoMain3 from "../components/courses/eco/EcoMain3";
import EcoPreview from "../components/courses/eco/preview/EcoPreview";
import EcoPreview2 from "../components/courses/eco/preview/EcoPreview2";
import EcoPreview3 from "../components/courses/eco/preview/EcoPreview3";

export const ecoRoutes = [
    {path: '/eco/', component: EcoMain, exact: true},
    {path: '/eco2/', component: EcoMain2, exact: true},
    {path: '/eco3/', component: EcoMain3, exact: true},
    {path: '/eco/preview', component: EcoPreview, exact: true},
    {path: '/eco2/preview', component: EcoPreview2, exact: true},
    {path: '/eco3/preview', component: EcoPreview3, exact: true},
]
