import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import {ecoRoutes} from "../router/routes";
import index from "three/examples/jsm/libs/dat.gui.module";

const AppRouter = () => {

    const eco3Path = (routes) => {
        let path = null;
        routes.forEach((item) => {
            item.path === 'eco3/preview' ? path = true : path = false;
        })

        return path;
    }

    return (
            <Switch>

                {ecoRoutes.map((route) =>
                    <Route
                        key = {route.path}
                        component = {route.component}
                        path = {route.path}
                        exact = {route.exact}
                    />)
                }
                {eco3Path(ecoRoutes) ? <Redirect to='/eco/preview'/> : <Redirect to='/eco3/preview'/>}


            </Switch>
    );
};

export default AppRouter;
